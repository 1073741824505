<template>
  <div style="width: 1000px;background: #ffffff;margin: 0 auto;">
    <div class="detail-content" v-if="IdValue === 2">
      <div class="content-title">
        <p class="con-t-x"><span @click="$router.back(-1)"></span>售后详情</p>
        <p class="title-img" v-if="numValue.status === 1">
          <img src="./image/cl.png" alt="" /> <span>处理中</span>
        </p>
        <p class="title-img" v-if="numValue.status === 0">
          <img src="./image/sh.png" alt="" /> <span>审核中</span>
        </p>
        <p class="title-img" v-if="numValue.status === 2">
          <img src="./image/wc.png" alt="" /> <span>已完成</span>
        </p>
        <p class="title-img" v-if="numValue.reviewStatus === 0">
          <img src="./image/gb.png" alt="" /> <span>已拒绝</span>
        </p>
      </div>
      <div class="border-btn"></div>
      <div class="two">
        <p class="two-1">售后申请信息</p>
        <p class="two-2">
          <span>售后单号:</span><span>{{ numValue.afterSaleOrderNumber }}</span>
        </p>
        <p class="two-3">
          <span>售后类型:</span><span>{{ numValue.typeName }}</span>
        </p>
        <p class="two-4">
          <span>售后原因:</span><span>{{ numValue.reason }}</span>
        </p>
        <p class="two-4">
          <span style="margin-right: 36px">联系人:</span
          ><span>{{ numValue.repairer }}</span>
        </p>
        <p class="two-4">
          <span>联系方式:</span><span>{{ numValue.repairerPhone }}</span>
        </p>
        <p class="two-5" v-if="chars">
          <span class="span-1">售后凭证:</span
          ><span class="span-2" v-for="item in chars" :key="item.index"
            ><el-image
              class="span-3"
              :preview-src-list="[item]"
              :src="item"
              alt=""
          /></span>
        </p>
        <div class="two-6">
          <p>补充说明:</p>
          <el-input v-model="numValue.comment" disabled type="textarea" />
        </div>
      </div>
      <div class="border-btn"></div>
      <div class="three">
        <p class="two-1">订单及商品信息</p>
        <p class="two-2">
          <span>订单编号：</span><span>{{ numValue.orderNumber }}</span>
        </p>
        <p class="two-3-1">
          <span>用户手机号：</span><span>{{ numValue.userMobile }}</span>
        </p>
        <p class="two-4">
          <span>商品名称：</span>
	        <template v-for="item in productInfo">
		        <span>{{ item.productName }}，</span>
	        </template>
        </p>
        <p class="two-2">
	        <span>商品单价：</span>
	        <template v-for="item in productInfo">
		        <span>{{ item.productPrice }}，</span>
	        </template>
        </p>
        <p class="two-3">
	        <span>购买数量：</span>
	        <template v-for="item in productInfo">
		        <span>{{ item.count }}，</span>
	        </template>
        </p>
        <p class="two-4">
          <span>订单金额：</span><span>¥{{ numValue.amount }}</span>
        </p>
      </div>
      <div class="border-btn"></div>
      <div class="four">
        <!-- 处理中 -->
        <div class="cl" v-if="numValue.status === 1">
          <el-timeline>
            <el-timeline-item
              timestamp="售后审核"
              color="#FFD2B2"
              placement="top"
            >
              <div class="four-1"><span>审核：</span><span>正在审核中</span></div>
              <div class="four-2">
                <span>提交时间：</span><span>{{ numValue.createdTime }}</span>
              </div>
            </el-timeline-item>
            <el-timeline-item
              timestamp="售后审核结果"
              color="#ce1200"
              placement="top"
            >
              <div class="four-1-1">
                <span>审核结果：</span><img src="./image/tg.png" alt="" /><span>{{
                  numValue.reviewStatus === 0 ? "拒绝" : "通过"
                }}</span>
              </div>
              <div class="four-2">
                <span>审核时间：</span><span>{{ numValue.reviewTime }}</span>
              </div>
              <div class="four-2-1">售后处理</div>
              <div class="four-2-2">
                <span>售后处理方式：</span
                ><el-input
                  type="textarea"
                  v-model="numValue.reviewReason"
                  disabled
                />
              </div>
              <!-- <div class="four-2">
                <span>处理时间：</span><span>正在审核中</span>
              </div> -->
            </el-timeline-item>
            <el-timeline-item>
              <div class="Dt">等待售后处理反馈...</div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- 审核中 -->
        <div class="SH" v-if="numValue.status === 0">
          <el-timeline>
            <el-timeline-item
              timestamp="售后审核"
              color="#ce1200"
              placement="top"
            >
              <div class="four-1"><span>审核：</span><span>正在审核中</span></div>
              <div class="four-2">
                <span>提交时间：</span><span>{{ numValue.createdTime }}</span>
              </div>
            </el-timeline-item>
            <el-timeline-item>
              <div class="Dt">等待审核结果...</div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- 已完成 -->
        <div class="WC" v-if="numValue.status === 2">
          <el-timeline>
            <el-timeline-item
              timestamp="售后审核"
              color="#FFD2B2"
              placement="top"
            >
              <div class="four-1"><span>审核：</span><span>正在审核中</span></div>
              <div class="four-2">
                <span>提交时间：</span><span>{{ numValue.createdTime }}</span>
              </div>
            </el-timeline-item>
            <el-timeline-item
              timestamp="售后审核结果"
              color="#FFD2B2"
              placement="top"
            >
              <div class="four-1-1">
                <span>审核结果：</span><img src="./image/tg.png" alt="" /><span>{{
                  numValue.reviewStatus === 0 ? "拒绝" : "通过"
                }}</span>
              </div>
              <div class="four-2">
                <span>审核时间：</span><span>{{ numValue.reviewTime }}</span>
              </div>
              <div class="four-2-1">售后处理</div>
              <div class="four-2-2">
                <span>售后处理方式：</span
                ><el-input
                  type="textarea"
                  v-model="numValue.reviewReason"
                  disabled
                />
              </div>
              <!-- <div class="four-2">
                <span>处理时间：</span><span>正在审核中</span>
              </div> -->
            </el-timeline-item>
            <el-timeline-item
              timestamp="售后处理完成"
              color="#FFD2B2"
              placement="top"
            >
              <div class="four-2-2">
                <span>处理完成备注：</span
                ><el-input type="textarea" v-model="numValue.remark" disabled />
              </div>
              <div class="four-2-2-2">
                <span>处理完成时间：</span><span>{{ numValue.dealTime }}</span>
              </div>
            </el-timeline-item>
            <el-timeline-item color="#ce1200">
              <div class="Dt">售后已结束</div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- 已拒绝 -->
        <div class="JJ" v-if="numValue.reviewStatus === 0">
          <el-timeline>
            <el-timeline-item
              timestamp="售后审核"
              color="#FFD2B2"
              placement="top"
            >
              <div class="four-1"><span>审核：</span><span>正在审核中</span></div>
              <div class="four-2">
                <span>提交时间：</span><span>{{ numValue.createdTime }}</span>
              </div>
            </el-timeline-item>
            <el-timeline-item
              timestamp="售后审核结果"
              color="#FFD2B2"
              placement="top"
            >
              <div class="four-1-1">
                <span>审核结果：</span><img src="./image/cha.png" alt="" /><span
                  >{{ numValue.reviewStatus === 0 ? "拒绝" : "通过" }}</span
                >
              </div>
              <div class="four-2">
                <span>审核时间：</span><span>{{ numValue.reviewTime }}</span>
              </div>
              <div class="four-2-2">
                <span>审核原因：</span
                ><el-input
                  type="textarea"
                  v-model="numValue.reviewReason"
                  disabled
                />
              </div>
            </el-timeline-item>
            <el-timeline-item color="#ce1200">
              <div class="Dt">售后已结束</div>
            </el-timeline-item>
          </el-timeline>
        </div>
        <!-- <div
          class="btn-button"
          @click="handleShe(numValue)"
          v-if="numValue.reviewStatus === 0"
        >
          重新提交
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, onMounted, defineEmits, ref, onBeforeMount} from "vue";
// import { AfterResubmit } from "../../../api/after/index.js";
import { useRoute } from "vue-router";
import { queryDetail } from "../../api/support/index.js";
const route = useRoute();
const numValue = ref({});
const productInfo = ref([])
let id = ref(route.params.id);
const getGuarantee = async () => {
  try {
    let data = await queryDetail(id.value);
    numValue.value = data.data;
	  productInfo.value = data.data.productPOS;
    // chars.value = numValue.value.mediaUrl ? numValue.value.mediaUrl.split(",") : "";
    chars.value = numValue.value.mediaUrls;
  } catch (error) {
    console.log(error);
  }
};
onBeforeMount(() => {
  getGuarantee();
});
// https://testshop.iningmei.com // 测试环境
// https://www.iningmei.com // 线上环境
const URL = ref("https://www.iningmei.com" + "/order");
const chars = ref([]);
// console.log(chars.value);
const IdValue = ref(2);
// 子组件传值给父组件
const emit = defineEmits(["changeId"]);
const handelClick = () => {
  emit("changeId", 1);
};
// 重新提交
const AfterApply = ref({});
// const handleShe = async (item) => {
//   console.log(item);
//   const afterSaleOrderNumber = ref(item.afterSaleOrderNumber);
//   try {
//     let res = await AfterResubmit(afterSaleOrderNumber.value);
//     if (res.code === 200) {
//       AfterApply.value = res.data;
//       IdValue.value = 3;
//       console.log(AfterApply.value, "重新提交");
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };
</script>

<style lang="less" scoped>
.detail-content {
  width: 900px;
  background: #ffffff;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 60px;
  .content-title {
    .con-t-x {
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 20px;
      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url("../../assets/images/order/return2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 8px;
        position: relative;
        top: 5px;
        left: 0;
        cursor: pointer;
        &:hover {
          background-image: url("../../assets/images/order/return3.png");
          cursor: pointer;
        }
      }
    }
    .title-img {
      img {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-bottom: 4px;
      }
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ce1200;
      }
    }
  }
  .border-btn {
    width: 884px;
    height: 1px;
    background: #e1e1e1;
    margin-top: 22px;
    margin-bottom: 30px;
  }
  .two {
    margin-left: 18px;
    .two-1 {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: 22px;
    }
    .two-2 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 14px;
      :nth-child(1) {
        margin-right: 21px;
      }
    }
    .two-3 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 14px;
      :nth-child(1) {
        margin-right: 21px;
      }
    }
    .two-4 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 14px;
      :nth-child(1) {
        margin-right: 21px;
      }
    }
    .two-5 {
      .span-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-right: 21px;
        position: relative;
        top: -75px;
      }
      .span-2 {
        display: inline-block;
        .span-3 {
          display: inline-block;
          width: 90px;
          height: 90px;
        }
      }
    }
    .two-6 {
      display: flex;
      margin-top: 15px;
      :nth-child(1) {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-right: 21px;
      }
      ::v-deep .el-textarea__inner {
        width: 499px;
        height: 80px;
      }
      ::v-deep .el-textarea {
        width: 499px;
      }
    }
  }
  .three {
    margin-left: 18px;
    .two-1 {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: 22px;
    }
    .two-2 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 14px;
      :nth-child(1) {
        margin-right: 21px;
      }
    }
    .two-3 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 14px;
      :nth-child(1) {
        margin-right: 21px;
      }
    }
    .two-3-1 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 14px;
      :nth-child(1) {
        margin-right: 8px;
      }
    }
    .two-4 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 14px;
      :nth-child(1) {
        margin-right: 21px;
      }
    }
  }
  .four {
    margin-left: 18px;
    ::v-deep .el-timeline-item__timestamp.is-top {
      font-size: 18px;
      color: #333;
    }
    .cl {
      .four-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 72px;
        }
      }
      .four-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 14px;
        :nth-child(1) {
          margin-right: 45px;
        }
      }
      .four-1-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 46px;
        }
        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          margin-bottom: 4px;
        }
      }
      .four-2-1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 29px;
        margin-bottom: 22px;
      }
      .four-2-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 18px;
        }
        ::v-deep .el-textarea__inner {
          width: 499px;
          height: 80px;
        }
        ::v-deep .el-textarea {
          width: 499px;
        }
      }
      .Dt {
        font-size: 12px !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
    .SH {
      .four-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 72px;
        }
      }
      .four-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 14px;
        :nth-child(1) {
          margin-right: 45px;
        }
      }
      .Dt {
        font-size: 12px !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
    .WC {
      .four-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 72px;
        }
      }
      .four-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 14px;
        :nth-child(1) {
          margin-right: 45px;
        }
      }
      .four-1-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 46px;
        }
        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          margin-bottom: 4px;
        }
      }
      .four-2-1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 29px;
        margin-bottom: 22px;
      }
      .four-2-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 18px;
        }
        ::v-deep .el-textarea__inner {
          width: 499px;
          height: 80px;
        }
        ::v-deep .el-textarea {
          width: 499px;
        }
      }
      .four-2-2-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 20px;
        }
      }
      .Dt {
        font-size: 12px !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
    .JJ {
      .four-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 72px;
        }
      }
      .four-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 14px;
        :nth-child(1) {
          margin-right: 45px;
        }
      }
      .four-1-1 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 46px;
        }
        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          margin-bottom: 4px;
        }
      }
      .four-2-2 {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        margin-top: 23px;
        :nth-child(1) {
          margin-right: 18px;
        }
        ::v-deep .el-textarea__inner {
          width: 499px;
          height: 80px;
        }
        ::v-deep .el-textarea {
          width: 499px;
        }
      }
      .Dt {
        font-size: 12px !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
    .btn-button {
      width: 120px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #aaaaaa;
      font-size: 11px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
      text-align: center;
    }
  }
}
::v-deep .el-input.is-disabled {
  width: 441px;
  height: 80px;
}
</style>
